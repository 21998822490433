import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ArticleCards from '../../../components/ArticleCards'
import NoResultsText from '../../../components/NoResultsText'

const Policies = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle(
        filter: { category: { title: { eq: "Policies" } } }
        sort: { fields: [featured, publishDate], order: [DESC, DESC] }
      ) {
        edges {
          node {
            ...ArticlesQuery
          }
        }
        totalCount
      }
    }
  `)

  const articles = data.allContentfulArticle.edges

  return (
    <>
      {articles.length ? (
        <ArticleCards articles={articles} />
      ) : (
        <NoResultsText>
          We haven't added any 'Policies' news yet 😢
        </NoResultsText>
      )}
    </>
  )
}

export default Policies
